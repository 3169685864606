.timelineEmptyHeader {
  opacity: 0.9;

  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.5px;
  text-align: center;
  color: var(--white);
}

.timelineEmptyText {
  max-width: 646px;

  font-size: 20px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.3px;
  text-align: center;
  color: var(--white);
}

.timelineBlockSearchHighlight {
  background-color: #ffea00;
}

#timelineModal {
  --max-width: 500px !important;
  --height: calc(100% - (var(--ion-safe-area-top) + 10px));
  --border-radius: 16px !important;
  background-color: rgba(0, 0, 0, 0.4);
}

#timelineModal ion-toolbar {
  padding-top: 0px !important;
}

#timelineModal .filtersModal {
  --padding-bottom: env(safe-area-inset-bottom) !important;
}

#timelineModal ion-content::part(scroll) {
  overflow-y: var(--overflow) !important;
  overscroll-behavior-y: contain !important;
}

.timelineFilterCheckboxContainer {
  --min-height: 52px;
  box-shadow: inset 0 -1px 0 0 #dee2e6;
  background-color: var(--white) !important;
  --inner-box-shadow: 0 !important;
  --inner-padding-top: 0 !important;
  --inner-padding-end: 0 !important;
  --padding-start: 0;
  --padding-end: 0;
  --background: var(--white);
  --border-color: #dee2e6 !important;
  /* --border-st: inset 0 -1px 0 0 #dee2e6; */
  /* padding-left: 16px; */
  /* padding-right: 8px; */
  cursor: pointer;
}

.timelineCheckboxContainerInner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
}

.timelineCheckboxLabel {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.25px;
  color: var(--grey-90);
  margin-left: 16px;
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timelineCheckboxLabelIndent {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.25px;
  color: var(--grey-90);
  margin-left: 32px;
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timelineRadioCheckbox {
  height: 44px;
  width: 44px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
  margin-right: 8px;
}

@media (max-width: 700px) {
  .evidenceEmptyImage {
    max-width: 250px !important;
    width: 250px !important;
    max-height: 250px !important;
    height: 250px !important;
  }

  .timelineEmptyHeader {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: -0.3px;
  }

  .timelineEmptyText {
    font-size: 17px;
    line-height: 1.53;
    letter-spacing: -0.3px;
    text-align: center;
  }
}
