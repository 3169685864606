.profileSettingsList {
  background-color: transparent !important;
}

.profileSettingIcon {
  width: 44px;
  height: 44px;
  margin-left: 4px;
}

.profileSettingIconRotate {
  animation: rotation 1s infinite linear !important;
  animation-fill-mode: forwards;
}

.profileSettingDisclosure {
  width: 44px;
  height: 44px;
  min-width: 44px;
  min-height: 44px;
  margin-right: 9px;
  display: flex;
  flex: -1;
}

.hcpcExportSpinnerContainer {
  width: 24px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.hcpcExportSpinner {
  width: 24px;
  height: 24px;
  color: var(--cta-blue) !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
