.homeCardButton {
  cursor: pointer;
  font-size: calc(17px + var(--accessibility-font-size)) !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.375;
  letter-spacing: -0.2px;
  color: var(--cta-blue);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.homeCardButtonContainer {
  display: flex;
  flex-direction: row;
}

.homeButtonFlexEnd {
  justify-content: flex-end !important;
}

.homeCardUserInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.homeCardUserInfoVertical {
  display: flex;
  flex-direction: column;
}

.hcpcCopyIcon,
.homeCardUserInfo .homeCardIcon,
.homeCardUserInfoButton .homeCardIcon {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}

.homeCardUserInfoButton {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  min-height: 44px !important;
  background-color: transparent;
}

.homeCardUserInfoButtonSlim {
  display: flex;
  min-width: 0;
  flex: -1;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  min-height: 26px !important;
  background-color: transparent;
  align-self: flex-start;
  margin-right: 8px;
}

.homeCardUserInfoButtonSlimWait {
  cursor: progress !important;
}

.homeCardUserInfoButtonSlimDisabled {
  cursor: not-allowed !important;
}

.homeCardCertificateSpinner {
  width: 15px !important;
  height: 15px !important;
  margin-left: 8px;
  color: var(--cta-blue) !important;
}

.homeCardIcon {
  height: 44px;
  width: 44px;
  margin-right: 4px;
  margin-left: -8px;
}

.homeCardCertificateSpinner {
  height: 24px;
  width: 24px;
  margin-right: 16px;
  --color: var(--cta-blue);
}

.homeCardUserDetails {
  font-size: 17px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  color: var(--grey-70);
}

.homeCardUserDetailsHeader {
  font-size: calc(13px + var(--accessibility-font-size)) !important;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.1px;
  color: var(--grey-70);
  margin-top: 12px;
}

.homeCardUserDetailsText {
  font-size: calc(17px + var(--accessibility-font-size)) !important;
  font-weight: var(--accessibility-bold-text-500);
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.3px;
  color: var(--grey-90);
}

.homeCardUserText {
  font-size: calc(15px + var(--accessibility-font-size)) !important;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.2px;
  color: var(--grey-90);
}

.homeCardCertificateText {
  font-size: 17px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  color: var(--cta-blue);
}

.homeCardCertificateTextGeneral {
  font-size: calc(17px + var(--accessibility-font-size));
  font-weight: var(--accessibility-bold-text-500);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  color: var(--cta-blue);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.homeCardHCPCRegistrationNumberContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.homeCardHCPCRegistrationNumber {
  font-size: calc(17px + var(--accessibility-font-size));
  font-weight: var(--accessibility-bold-text-500);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  color: var(--grey-90);
}

.homeCardUserCopyButton {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  min-height: 24px !important;
  background-color: transparent;
}

.homeCardUserCopyButtonText {
  font-size: calc(15px + var(--accessibility-font-size));
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.2px;
  text-align: right;
  color: var(--cta-blue);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.hcpcCopyIcon {
  width: 24px;
  height: 24px;
  margin-left: 4px;
}
