.evidencePartsCheckboxContainer {
  --min-height: 44px;
  box-shadow: none !important;
  background-color: var(--white) !important;
  --inner-box-shadow: 0 !important;
  --inner-padding-top: 0 !important;
  --inner-padding-end: 0 !important;
  --padding-start: 0;
  --padding-end: 0;
  --background: var(--white);
  --border-color: transparent !important;
  --border-bottom-width: 1px !important;
  /* --border-st: inset 0 -1px 0 0 #dee2e6; */
  /* padding-left: 16px; */
  /* padding-right: 8px; */
  cursor: pointer;
}

.evidencePartsCheckboxContainerInner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
}

.programRoleCheckboxContainerInner {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.programCertificateAttachmentContainer {
  display: flex;
  height: 50px;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  padding-right: 12px;
  border: solid 0.5px var(--grey-50);
  background-color: var(--grey-10);
  margin-bottom: -12px;
}

.programRoleDescription {
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: var(--grey-60);
}

.evidencePartsCheckboxLabel {
  font-size: 17px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.25px;
  color: var(--grey-90);
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.evidencePartsCheckboxLabelSelected {
  font-weight: 600 !important;
}

.evidencePartsRadioCheckbox {
  height: 44px;
  width: 44px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
  /* margin-right: 8px; */
}

.evidencePartsRadioContainer {
  display: flex;
  justify-content: center !important;
  /* --border-width: 0 !important; */
  --border-color: transparent;
  /* --background: var(--white); */
  --ion-item-background: var(--white);
  --min-height: 44px;
  border-style: unset !important;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  --inner-padding-end: 0px !important;
  --ion-safe-area-left: 0px !important;
  --ion-safe-area-right: 0px !important;
}

.evidencePartsRadioLabel {
  font-size: 17px !important;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.3px;
  color: var(--grey-90) !important;
  background-color: transparent;
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}
