.exportButton {
  width: 100%;
  height: 50px;
  max-width: 358px !important;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--white);
  flex-shrink: 0;
  --border-radius: 8px !important;
  /* --background: var(--grey-20);
  --background-hover: var(--grey-40);
  --background-activated: var(--grey-50);
  --background-focused: var(--grey-50); */
}

.generateButton {
  width: 100%;
  height: 56px;
  border-radius: 8px;
  --border-radius: 8px !important;

  background-color: var(--cta-blue) !important;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  align-self: center;
  color: var(--white);
  text-transform: none;
  margin-top: 9px !important;
}

.evidenceFilterPopover {
  --min-width: 375px !important;
  --max-height: 408px !important;
  margin-top: 14px;
}

.evidenceFilterPopover #ion-react-wrapper {
  overflow: hidden !important;
}

.evidenceFilterPopover::part(backdrop) {
  margin-top: -14px !important;
}

.evidenceFilterPopover::part(arrow) {
  display: none !important;
}

.evidenceFilterPopover:host {
  position: relative !important;
  display: flex;
  flex-direction: column;
}

.evidenceFilterPopover::part(content) {
  overflow-y: hidden !important;
  /* padding-top: 44px; */
}

.evidenceFilterPopover::part(scroll) {
  overscroll-behavior-y: unset !important;
}

.evidenceFilterPopover ion-content {
  overscroll-behavior-y: unset !important;
  --offset-top: unset !important;
  --offset-bottom: unset !important;
  background-color: #ffffff !important;
}

.exportModalContainer {
  display: flex;
  height: 100%;
  width: 100%;
  align-self: stretch;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 24px;
}

.exportModalTitle {
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: var(--grey-70);
  margin-bottom: 6px;
}

.exportFilenameInput {
  /* Container */
  height: 50px;
  width: 100%;
  border-radius: 8px;
  border: solid 0.5px var(--grey-50);
  background-color: var(--grey-10);
  padding-left: 12px;
  padding-right: 12px;
  /* Text */

  font-size: 16px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: var(--grey-90);
  margin-bottom: 10px;
}

.exportingEvidenceSpinner {
  height: 24px;
  width: 24px;
  --color: var(--white);
}

.evidenceCardDateContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.evidenceTagsContainer {
  display: inline;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.evidenceCardDate {
  /* font-size: 12px; */
  font-size: calc(12px + var(--accessibility-font-size)) !important;
  font-weight: var(--accessibility-bold-text-500);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.1px;
  color: var(--grey-70);
}

.evidenceCardTitle {
  /* font-size: 20px; */
  font-size: calc(20px + var(--accessibility-font-size)) !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height) !important;
  letter-spacing: -0.1px;
  color: var(--grey-90);
  margin-top: 4px;
  margin-bottom: 8px;
}

.evidenceCardDotSeparator {
  display: inline-block;
  align-self: center;
  width: 2px;
  height: 2px;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 3.5px;
  margin-bottom: 3.5px;
  background-color: var(--grey-70);
}

.evidenceCardActivity {
  /* font-size: 12px; */
  font-size: calc(12px + var(--accessibility-font-size)) !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height) !important;
  letter-spacing: normal;
  color: #236aad;
}

.evidenceCardProgramActivity {
  font-size: calc(12px + var(--accessibility-font-size)) !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height) !important;
  letter-spacing: normal;
  color: #026f8a;
}

.evidenceStandardTags {
  /* font-size: 12px; */
  font-size: calc(12px + var(--accessibility-font-size)) !important;
  font-weight: var(--accessibility-bold-text-500);
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height) !important;
  letter-spacing: -0.1px;
  color: #2f855a;
  overflow: visible;
}

.evidenceCustomTags {
  /* font-size: 12px; */
  font-size: calc(12px + var(--accessibility-font-size)) !important;
  font-weight: var(--accessibility-bold-text-500);
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height) !important;
  letter-spacing: -0.1px;
  color: var(--grey-70);
  overflow: visible;
}

.evidenceSearchHeader {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: calc(980px + 32px) !important;
  padding-bottom: 0px;
  padding-left: calc(16px + var(--ion-safe-area-left)) !important;
  padding-right: calc(16px + var(--ion-safe-area-right)) !important;
  background-color: var(--header-background);
  margin-top: 4px;
  margin-bottom: 10px;
}

.evidenceSearchInputContainer {
  display: flex;
  flex: 1;
  height: 36px !important;
  width: 100% !important;
  background-color: var(--white);
  border-radius: 0px !important;
  border-width: 0px !important;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(73, 80, 87, 0.2);
  border-radius: 8px !important;
}

.evidenceSearchIcon {
  width: 36px;
  height: 36px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}

.searchInputField {
  /* Container */
  --padding-start: 0px !important;
  width: calc(100% - 44px);
  outline: none;
  border: 0 !important;
  /* background-color: var(--white); */
  background-color: transparent;
  height: 36px;
  /* Text */
  font-family: Arial;
  font-size: 16px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--grey-90);
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.searchInputField:focus {
  border: 0 !important;
}

.searchClearButton {
  margin-right: -14px;
  flex-shrink: 0 !important;
}

.evidenceResultsText {
  font-size: 16px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.3px;
  text-align: right;
  color: var(--white);
  margin-left: 32px;
  min-width: 63px;
}

.icon-44 {
  width: 44px;
  height: 44px;
}

.evidenceSearchInputContainer .icon-44 {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}

.evidenceSearchInputContainer ion-button {
  text-transform: none;
  --background: transparent;
  font-size: 16px;
  font-weight: var(--accessibility-bold-text-500);
  --background: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  letter-spacing: -0.2px;
  margin-top: -4.5px;
}

#exportModal {
  --width: 350px !important;
  /* --height: 400px !important; */
  --height: auto;
  --border-radius: 10px !important;
  background-color: rgba(0, 0, 0, 0.4);
}

#exportModalSmall {
  --width: 350px !important;
  /* --height: 400px !important; */
  --height: auto;
  --border-radius: 10px !important;
  background-color: rgba(0, 0, 0, 0.4);
}

#evidenceFilterModal {
  --max-width: 500px !important;
  --height: calc(100% - (var(--ion-safe-area-top) + 10px));
  --border-radius: 16px !important;
  background-color: rgba(0, 0, 0, 0.4);
}

#evidenceFilterModal ion-toolbar {
  padding-top: 0px !important;
}

#evidenceFilterModal ion-content::part(scroll) {
  overflow-y: var(--overflow) !important;
  overscroll-behavior-y: contain !important;
}

#exportModal .navBar,
#exportModalSmall .navBar,
#evidenceFilterModal .navBar {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* #evidenceFilterModal> */
.filtersModal {
  --padding-bottom: env(safe-area-inset-bottom) !important;
  height: 364px !important;
  overflow-y: scroll !important;
}

.filtersModalTagsHeader {
  /* Container */
  padding: 5px 16px;
  border: solid 1px var(--grey-50);
  background-color: var(--grey-20);
  /* Text */

  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: var(--grey-90);
}

.filtersRadioContainer {
  display: flex;
  justify-content: center !important;
  --border-width: 0 !important;
  --border-color: #adb5bd;
  --background: var(--white);
  --ion-item-background: var(--white);
  --min-height: 56px;
  border-style: unset !important;
  --padding-start: 16px !important;
  --padding-end: 0px !important;
  --inner-padding-end: 0px !important;
}

.filtersRadioLabel {
  font-size: 16px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.25px;
  color: var(--grey-90) !important;
  background-color: transparent;
}

.filtersCheckboxContainer {
  --min-height: 52px;
  box-shadow: inset 0 -1px 0 0 #dee2e6;
  background-color: var(--white) !important;
  --inner-box-shadow: 0 !important;
  --inner-padding-top: 0 !important;
  --inner-padding-end: 0 !important;
  --padding-start: 0;
  --padding-end: 0;
  --background: var(--white);
  --border-color: #dee2e6 !important;
  --border-bottom-width: 1px !important;
  /* --border-st: inset 0 -1px 0 0 #dee2e6; */
  /* padding-left: 16px; */
  /* padding-right: 8px; */
  cursor: pointer;
}

.filtersCheckboxContainerInner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
}

.filtersCheckboxLabel {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.25px;
  color: var(--grey-90);
  margin-left: 16px;
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filtersRadioCheckbox {
  height: 44px;
  width: 44px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
  margin-right: 8px;
}

.evidenceEmptyHeader {
  opacity: 0.9;

  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.5px;
  text-align: center;
  color: var(--grey-70);
}

.evidenceEmptyImage {
  width: 300px;
  height: 300px;
  object-fit: contain;
  align-self: center;
  margin-top: 24px;
  margin-bottom: 32px;
}

.evidenceEmptyContainer {
  min-height: calc(100vh - 157px);
  margin-left: auto;
  margin-right: auto;
  max-width: 980px;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 980px !important;
  padding-bottom: 0px;
  padding-left: 16px;
  padding-right: 16px;
}

.evidenceEmptyText {
  max-width: 646px;

  font-size: 20px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.3px;
  text-align: center;
  color: var(--grey-70);
}

.addEvidenceButton {
  width: 100%;
  max-width: 350px !important;
  height: 52px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  align-self: center;
  color: var(--white);
  margin-top: 32px !important;
  --border-radius: 8px;
}

.evidenceHCPCPill {
  /* Container */
  display: flex;
  align-self: flex-start !important;
  height: 20px;
  width: 54px;
  /* padding: 5px 8px 5px 8px; */
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 14px;
  background-color: var(--HCPC-background) !important;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  pointer-events: none;
  margin-top: 8px;
  /* Text */

  font-size: calc(13px + var(--accessibility-font-size)) !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.05;
  color: var(--white);
}

.readEvidenceTitle {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -0.4px;
  color: var(--grey-90) !important;
}

.readEvidenceBlock {
  margin-top: 20px;
}

.readEvidenceHeader {
  font-size: 13px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.1px;
  color: var(--grey-90) !important;
  margin-bottom: 4px;
}

.readEvidenceText {
  font-size: 17px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  color: var(--grey-90) !important;
}

.readEvidenceAttachment {
  font-size: 17px;
  font-weight: var(--accessibility-bold-text-500);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  color: var(--cta-blue) !important;
  text-decoration: underline;
}

.readEvidenceAttachmentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.readEvidenceAttachmentContainerWait {
  cursor: progress !important;
}

.readEvidenceAttachmentContainerDisabled {
  cursor: not-allowed !important;
}

.readEvidenceSpinnerContainer {
  margin-left: 4px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.readEvidenceAttachmentIconSpinner {
  width: 14px;
  height: 14px;
  color: var(--cta-blue) !important;
}

@media (max-width: 500px) {
  .evidenceCard {
    padding: 14px !important;
    cursor: pointer;
  }

  .evidenceCardTitle {
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: -0.2px;
    color: var(--grey-90);
    margin-top: 4px;
    margin-bottom: 8px;
  }

  #exportModalSmall {
    --width: 350px !important;
    --height: auto !important;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

@media (max-width: 700px) {
  .evidenceEmptyImage {
    max-width: 250px !important;
    width: 250px !important;
    max-height: 250px !important;
    height: 250px !important;
  }

  .evidenceEmptyHeader {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: -0.3px;
  }

  .evidenceEmptyText {
    font-size: 17px;
    line-height: 1.53;
    letter-spacing: -0.3px;
    text-align: center;
  }
}

@media (max-height: 450px) {
  #exportModalSmall #evidenceFilterModal {
    --width: 350px !important;
    --height: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
}