.menuItemButton {
  display: flex;
  justify-content: center !important;
  --background: transparent !important;
  --border-width: 0 !important;
  box-shadow: unset !important;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  --inner-padding-end: 0px !important;
  --inner-padding-end: 0 !important;
  padding-right: 0px !important;
  --ion-safe-area-left: 0px !important;
  --ion-safe-area-right: 0px !important;
  --border-color: transparent !important;
}

.menuItemButton:last-child {
  box-shadow: unset !important;
}

.menuItemButton button {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.menuItemButtonContainer {
  height: 64px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 0px !important;
}

.menuItemButtonInnerContainer {
  display: flex;
  flex: -1;
  align-items: center;
  justify-content: flex-start !important;
}

.menuItemButtonIcon {
  width: 44px;
  height: 44px;
  margin-left: 4px;
}

.menuItemIconRotate {
  animation: rotation 1s infinite linear !important;
  animation-fill-mode: forwards;
}

.menuItemButtonTitle {
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: calc(16px + var(--accessibility-font-size)) !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.25px;
  color: var(--grey-90);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.menuItemButtonDisclosure {
  width: 44px;
  height: 44px;
  margin-right: 9px;
}
