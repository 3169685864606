.timelineContainer {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 96px;
}

ion-page:has(.timelineEmptyContainer) {
  background-color: var(--header-background);
}

.timelineContainer:has(.timelineEmptyContainer) {
  background-color: var(--header-background);
  padding-bottom: 30px;
}

.timelineEmptyContainer {
  max-width: 980px;
  min-height: calc(100vh - 141px) !important;
  align-self: center !important;
  background-color: var(--header-background);
}

.timelineResultsContainer {
  min-height: 30px;
  max-height: 70px;
  background-color: #e8ecef;
  display: flex;
  justify-content: center;
}

.timelineResultsInnerContainer {
  max-width: 980px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: calc(16px + var(--ion-safe-area-left));
  padding-right: calc(16px + var(--ion-safe-area-right));
}

.timelineSearchResultsText {
  font-size: calc(11px + var(--accessibility-font-size));
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: normal;
  color: var(--grey-90);
}

.exportButtonContainer {
  display: flex;
  justify-content: center;
}

.exportButtonInnerContainer {
  position: relative;
  display: flex;
  max-width: 980px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: calc(16px + var(--ion-safe-area-left));
  padding-right: calc(16px + var(--ion-safe-area-right));
  /* background-color: var(--white); */
  justify-content: center;
}

.timelineBlock {
  display: flex;
  justify-content: center;
}

.timelineBlockInner {
  position: relative;
  max-width: 980px;
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: calc(16px + var(--ion-safe-area-left));
  padding-right: calc(16px + var(--ion-safe-area-right));
  background-color: var(--white);
  box-shadow: inset 0 -1px 0 0 #ced4da;
}

.timelineBlockHeader {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 4px;
}

.timelineBlockAppIcon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.timelineBlockHeaderAppTitle {
  font-size: calc(12px + var(--accessibility-font-size));
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: normal;
}

.parapassTitle {
  color: #00736d;
}

.plusTitle {
  color: #5d9a1c;
}

.timelineBlockText {
  max-width: calc(100% - 200px) !important;
  display: block;
  font-size: calc(16px + var(--accessibility-font-size));
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.25px;
  color: var(--grey-90);
}

.timelineBlockTextBold {
  font-size: 600 !important;
}

.timelineBlockDot {
  width: 2px;
  height: 2px;
  background-color: #868e96;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 4px;
}

.timelineBlockHeaderType {
  font-size: calc(12px + var(--accessibility-font-size));
  font-weight: var(--accessibility-bold-text-500);
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.1px;
  color: var(--grey-60);
}

.timelineDateText {
  font-size: calc(13px + var(--accessibility-font-size));
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.1px;
  color: var(--grey-90);
  margin-top: 12px;
}

.timelineAddEvidenceButton {
  width: auto;
  min-width: 132px !important;
  height: 28px !important;
  /* max-width: 200px; */
  min-height: 28px !important;
  max-height: 28px !important;
  font-size: calc(13px + var(--accessibility-font-size));
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.05px;
  text-align: center;
  color: var(--cta-blue);
  position: absolute;
  right: calc(16px + var(--ion-safe-area-right));
  top: 0px;
  bottom: 0px;
  margin: auto 0;
  border-radius: 6px;
  --border-radius: 6px;
  --background: var(--grey-20);
  --background-hover: var(--grey-40);
  --background-activated: var(--grey-50);
  --background-focused: var(--grey-50);
  display: flex;
  align-items: center;
  justify-content: center;
}

.timelineAddEvidenceButtonContainer {
  display: flex;
  flex-direction: row;
  /* width: 120px !important; */
  align-items: center;
}

.timelineAddEvidenceButtonIconContainer {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -4px;
}

.timelineAddEvidenceButtonIcon {
  width: 12px;
  height: 12px;
}

.timelineReadEvidenceButton {
  width: auto;
  height: 28px !important;
  /* min-width: 48px; */
  min-height: 28px;
  /* max-width: 84px; */
  /* height: fit-content !important; */
  /* max-height: 38px; */
  font-size: calc(13px + var(--accessibility-font-size));
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.05px;
  text-align: center;
  color: var(--cta-blue);
  background-color: var(--grey-20) !important;
  --background: var(--grey-20);
  --background-hover: var(--grey-40);
  --background-activated: var(--grey-50);
  --background-focused: var(--grey-50);
  position: absolute;
  right: calc(16px + var(--ion-safe-area-right));
  top: 0px;
  bottom: 0px;
  margin: auto 0;
  border-radius: 6px;
  --border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timelineReadEvidenceButton {
  color: var(--cta-blue) !important;
}

.timelinePopover {
  --min-width: 375px !important;
  --max-height: 408px !important;
  margin-top: 14px;
}

.timelinePopover::part(backdrop) {
  margin-top: -14px !important;
}

.timelinePopover #ion-react-wrapper {
  overflow: hidden !important;
}

.timelinePopover::part(arrow) {
  display: none !important;
}

.timelinePopover:host {
  display: flex;
  flex-direction: column;
  background-color: #ffffff !important;
}

.timelinePopover ion-content {
  overscroll-behavior-y: unset !important;
  --offset-top: unset !important;
  --offset-bottom: unset !important;
  background-color: #ffffff !important;
}

@media (max-width: 500px) {
  .timelineAddEvidenceButton,
  .timelineReadEvidenceButton {
    bottom: 10px !important;
    top: unset !important;
    margin: unset !important;
    right: calc(16px + var(--ion-safe-area-right));
  }

  .timelineBlockText {
    max-width: unset !important;
  }

  .timelineDateText {
    max-width: calc(100% - 200px) !important;
  }
}
