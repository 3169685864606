#certificateModal ion-toolbar {
  padding-top: max(var(--ion-safe-area-top), var(--ion-statusbar-padding)) !important;
}

.techniqueCertificateContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.techniqueCertificateButtonContainer {
  margin-bottom: 4px;
}

.programCardButtonBlue {
  width: 100%;
  height: 50px;
  max-width: 358px !important;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--white);
  flex-shrink: 0;
  align-self: center;
  margin-top: 16px;
  --border-radius: 10px !important;
}

.techniqueRequiredSkillContainer {
  display: flex;
  flex-direction: column;
}

.programCardButtonBlueText {
  font-size: 16px;
  /* font-size: calc(16px + var(--accessibility-font-size)) !important; */
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--white);
}

.techniqueCertificateMain {
  display: flex;
  flex-direction: column;
}

.programAddCertificateTitle {
  font-family: Inter;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: -0.3px;
  color: var(--grey-90);
  margin-bottom: 4px;
}

.programAddCertificateDescription {
  font-family: Inter;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
  color: var(--grey-90);
  margin-bottom: 20px;
}

.programAddCertificate {
  font-family: Inter;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: var(--grey-70);
  margin-bottom: 6px;
}

.programAddCertificateButton {
  max-width: 375px;
  height: 50px;
  width: 100% !important;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  align-self: center;
  color: var(--white);
  --background: var(--cta-blue);
  margin-top: 8px;
  /* margin-bottom: 4px !important; */
}

.programAddCertificateButtonContainer {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.programAddCertificateName {
  color: var(--cta-blue);
  display: block;
  /* or in-line block according to your requirement */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.techniqueRequiredSkillMonthContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 44px;
  padding: 12px 0 12px;
  box-shadow: inset 0 -1px 0 0 #dee2e6;
}

.techniqueRequiredSkillMonthRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.techniqueRequiredSkillMonthText {
  font-family: Inter;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
  color: var(--grey-90);
}

.techniqueRequiredSkillMonthCountContainer {
  display: flex;
  flex-direction: row;
}

.techniqueRequiredSkillMonthCountAchieved {
  font-family: Inter;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.2px;
  text-align: right;
  color: var(--grey-60);
}

.techniqueRequiredSkillMonthCountAchievedGreen {
  color: #06a14f !important;
}

.techniqueRequiredSkillMonthCountAchievedRed {
  color: var(--cta-red) !important;
}

.techniqueRequiredSkillMonthCountRequired {
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.2px;
  text-align: right;
  color: var(--grey-60);
}

.techniqueRequiredSkillMonthErrorText {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.1px;
  color: var(--cta-red);
}
