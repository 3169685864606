.guidelineCard {
  background-color: var(--white);
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
}

.guidelineCardTitle {
  /* Container */
  min-height: 36px;
  width: 100%;
  /* padding: 16px 20px; */
  display: flex;
  align-items: center;
  /* Text */
  font-size: calc(17px + var(--accessibility-font-size)) !important;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.2px;
  color: var(--grey-80);
}

.guidelineCardButtonItem {
  display: flex;
  justify-content: center !important;
  --background: transparent !important;
  --border-width: 0 !important;
  box-shadow: inset 0 -1px 0 0 #ced4da !important;
  --border-color: #ced4da !important;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  --inner-padding-end: 0px !important;
  --inner-padding-end: 0 !important;
  padding-right: 0px !important;
  --ion-safe-area-left: 0px !important;
  --ion-safe-area-right: 0px !important;
}

.guidelineCardButtonInner {
  min-height: calc(62px + var(--accessibility-font-size));
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.guidelineCardButton {
  min-height: calc(62px + var(--accessibility-font-size));
  width: 100%;
  /* padding-left: 20px; */
  display: flex;
  align-items: center;
  box-shadow: inset 0 -1px 0 0 #ced4da;
  flex-direction: row;
  cursor: pointer;
}

.guidelineCardButtonNoBorder {
  box-shadow: none !important;
  --border-color: transparent !important;
}

.guidelineAppIcon {
  width: 36px;
  height: 36px;
}

.guidelineReflectionIcon {
  width: 44px;
  height: 44px;
  margin-left: -4px;
  margin-right: -4px;
}

.guidelineButtonText {
  font-size: calc(17px + var(--accessibility-font-size)) !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.2px;
  color: var(--cta-blue);
  margin-left: 14px;
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.guidelineCard img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}

@media (max-width: 500px) {
  .guidelineCardTitle {
    /* Container */
    min-height: calc(36px + var(--accessibility-font-size));
    width: 100%;
    /* padding: 16px 14px; */
    display: flex;
    align-items: center;
    box-shadow: inset 0 -1px 0 0 #ced4da;
    /* Text */
    font-size: calc(17px + var(--accessibility-font-size)) !important;
    font-weight: 800;
    font-style: normal;
    line-height: var(--accessibility-line-height);
    letter-spacing: -0.2px;
    color: var(--grey-80);
  }

  .guidelineButtonText {
    font-size: 15px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: var(--accessibility-line-height);
    letter-spacing: -0.2px;
    color: var(--cta-blue);
    margin-left: 14px;
  }
}
