.reflectionsContainer {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 96px;
}

.reflectionsInnerContainer {
  max-width: 980px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: calc(16px + var(--ion-safe-area-left));
  padding-right: calc(16px + var(--ion-safe-area-right));
  padding-bottom: 56px;
}

.reflectionsHeaderContainer {
  height: 54px;
  box-shadow: inset 0 -0.5px 0 0 #adb5bd;
  background-color: var(--white);
  display: flex;
  justify-content: center;
}

.reflectionActivityList {
  background-color: transparent;
  padding: 0 !important;
  height: 44px !important;
}

.reflectionActivityItem {
  --padding-start: 0 !important;
  --placeholder-color: var(--grey-60);
}

.reflectionActivityItem::part(text) {
  font-size: 20px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: var(--grey-60);
}

.reflectionActivityItem::part(placeholder) {
  color: var(--grey-60);
}

ion-select {
  font-size: 20px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: var(--grey-90);
  --placeholder-color: var(--grey-60);
  --placeholder-opacity: 1;
  --padding-start: 0 !important;
  padding-left: 0 !important;
  padding: 0 !important;
}

ion-select::part(icon) {
  content: url("../../assets/images/Button_Dropdown@3x.png");
  width: 44px;
  height: 44px;
  opacity: 1;
}

ion-select-popover {
  background-color: var(--white);
  border-radius: 6px;
}

ion-select-popover ion-list {
  border-radius: 6px;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--grey-50);
  background-color: var(--white);
}

.reflectionsHeader {
  height: 54px;
  width: 100%;
  max-width: 980px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
}

.reflectionsHeaderDropdownText {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: var(--grey-90);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none !important;
}

.reflectionsHeaderDropdown {
  height: 44px;
  width: 44px;
  margin-left: -7.5px;
  pointer-events: none;
}

.evidenceDateHeader {
  /* Container */
  max-width: 980px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: calc(16px + var(--ion-safe-area-left));
  padding-right: calc(16px + var(--ion-safe-area-right));
  align-self: center;
  /* Text */

  /* font-size: 13px; */
  font-size: calc(13px + var(--accessibility-font-size)) !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.2px;
  color: var(--grey-70);
  margin-top: 4px;
  margin-bottom: 4px;
}

.evidenceFilterRadioLabel {
  font-size: 16px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.25px;
  color: var(--grey-90) !important;
  background-color: transparent;
}

.reflectionActivitySelectItem {
  --background: transparent !important;
}

.select-popover {
  width: 100%;
  --width: 100% !important;
  max-width: min(calc(100% - 32px), 490px) !important;
}

.reflectionActivitySelectItem ion-select::part(native) {
  --inner-padding-start: 0 !important;
  --padding-start: 0 !important;
  padding-left: 0px !important;
}

.reflectionActivitySelectItem ion-select::part(text) {
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.2px;
  color: var(--grey-90);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.reflectionActivitySelectItem::part(placeholder),
.reflectionActivitySelectItem::part(text) {
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

@media (max-width: 500px) {
  .reflectionsHeader {
    justify-content: center !important;
  }

  .reflectionActivityItem::part(text) {
    font-size: 17px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.3px;
    color: var(--grey-60);
    max-width: 300px !important;
    text-decoration: var(--accessibility-underline-links);
    text-decoration-color: currentColor;
  }

  ion-select {
    font-size: 17px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.3px;
    color: var(--grey-90);
    --placeholder-color: var(--grey-60);
    --placeholder-opacity: 1;
    --padding-start: 0 !important;
    padding-left: 0 !important;
    max-width: 300px !important;
  }
}