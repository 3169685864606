.onboardingPage {
  --background: var(--onboarding-background);
}

.onboardingContent {
  max-width: 980px;
  min-height: 100%;
  margin: auto;
  padding-top: calc(40px + var(--ion-safe-area-top));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.onboardingTitle {
  font-family: Inter;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.5px;
  text-align: center;
  color: var(--white);
  margin-bottom: 8px;
}

.onboardingSubtitle {
  font-family: Inter;
  font-size: 20px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.3px;
  text-align: center;
  color: var(--white);
  margin-bottom: 24px;
}

.onboardingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  align-self: center !important;
  padding-left: var(--ion-safe-area-left) !important;
  padding-right: var(--ion-safe-area-right) !important;
}

.onboardingButton {
  width: 200px;
  height: 60px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.1px;
  text-align: center;
  color: var(--cta-blue);

  --background: #ffffff;
  --background-hover: #e8ecef;
  --background-activated: #dee2e6;
  --background-focused: #dee2e6;

  --border-radius: 8px;
  --border-color: #000;
  --border-style: solid;
  --border-width: 1px;
  --ripple-color: #dee2e6;
}

.onboardingWideButton {
  width: 100%;
  height: 50px;
  max-width: 350px !important;
  font-family: Inter;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--cta-blue);

  --background: #ffffff;
  --background-hover: #e8ecef;
  --background-activated: #dee2e6;
  --background-focused: #dee2e6;

  --border-radius: 8px;
  --border-color: transparent;
  --border-style: solid;
  --border-width: 1px;
  --ripple-color: #dee2e6;
}

.onboarding-swiper {
  max-width: 640px !important;
  width: 640px !important;
  margin-top: 0 !important;
}

.sliderContainer {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.onboardingDisclosure {
  height: 44px !important;
  width: 44px !important;
  margin-left: 42px;
  cursor: pointer;
}

.onboardingDisclosureCompact {
  height: 44px !important;
  width: 44px !important;
  margin-left: 42px;
  cursor: pointer;
}

.onboardingDisclosurePrevious {
  margin-left: 0px;
  margin-right: 42px;
}

.onboardingSlideImage {
  max-width: 350px !important;
  max-height: 350px !important;
  margin-top: 24px;
  margin-bottom: 12px;
}

.onboardingSlide {
  max-width: 640px;
  max-height: 636px;
  width: 640px !important;
  height: 635px !important;
  background-color: #026f8a;
  padding: 32px 24px;
  /* margin-top: 32px; */
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  border-radius: 4px;
}

.onboardingSlideHeader {
  font-family: Inter;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -0.4px;
  text-align: center;
  color: var(--white);
}

.onboardingSlideHeaderSmall {
  font-family: Inter;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.1px;
  text-align: center;
  color: var(--white);
}

.onboardingSlideText {
  font-family: Inter;
  font-size: 17px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  text-align: center;
  color: var(--white);
  margin-top: 8px;
}

.onboardingSlideTextLeft {
  font-family: Inter;
  font-size: 17px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  text-align: start !important;
  color: var(--white);
  margin-top: 8px;
}

.onboardingSlideTickContainer {
  margin-top: 8px;
  margin-bottom: 15.5px !important;
}

.onboardingSlideTick {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.onboardingSlideTickImage {
  width: 20px !important;
  height: 20px !important;
  margin-right: 12px;
}

.onboardingSlideTickText {
  font-family: Inter;
  font-size: 16px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: var(--white);
}

.onboardingFeaturesHeader {
  font-family: Inter;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.2px;
  color: var(--grey-90);
  margin-top: 24px;
}

.onboardingFeaturesTickContainer {
  margin-top: 8px;
  margin-bottom: 15.5px !important;
}

.onboardingFeaturesTick {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 12px;
}

.onboardingFeaturesTickImage {
  width: 20px !important;
  height: 20px !important;
  margin-right: 12px;
  margin-top: 2px;
}

.onboardingFeaturesTickText {
  font-family: Inter;
  font-size: 16px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: var(--grey-90);
}

.onboardingProgressContainer {
  width: 100%;
  max-width: 640px;
  margin-top: 16px;
  margin-bottom: 24px;
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  justify-content: center;
}

.onboardingProgressContainerCompact {
  align-items: center !important;
  justify-content: space-between !important;
  padding: 0 !important;
  padding-left: 20px !important;
  margin-right: 20px !important;
}

.onboardingDots {
  display: flex;
  flex-direction: row;
  margin-bottom: 38px;
}

.onboardingProgressDot {
  width: 8px;
  height: 8px;
  border-radius: 25px;
  opacity: 0.3;
  background-color: var(--white);
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.onboardingProgressDotFilled {
  width: 8px;
  height: 8px;
  border-radius: 25px;
  opacity: 1;
  background-color: var(--white);
  cursor: unset;
}

.onboardingFooter {
  font-family: Inter;
  font-size: 15px !important;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
  text-align: center;
  color: var(--white);
  margin-bottom: 32px;
  margin-left: calc(20px + var(--ion-safe-area-left));
  margin-right: calc(20px + var(--ion-safe-area-right));
  margin-top: auto !important;
}

.onboardingFooterLogin {
  margin-top: 32px !important;
}

.onboardingFooter button {
  color: var(--white);
  background: none;
  font-family: Inter;
  font-size: 15px !important;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
  text-align: center;
  color: var(--white);
  margin-top: auto !important;
  text-decoration: underline !important;
}

@media (max-height: 700px) {
  .onboardingDots {
    margin-bottom: 24px;
  }
}

@media (max-width: 700px) {
  .onboardingTitle {
    font-family: Inter;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: -0.3px;
    text-align: center;
    color: var(--white);
    margin-bottom: 8px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .onboardingSubtitle {
    font-family: Inter;
    font-size: 17px;
    font-weight: var(--accessibility-bold-text);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: -0.3px;
    text-align: center;
    color: var(--white);
    margin-bottom: 18px;
    margin-left: 20px;
    margin-right: 20px;
  }

  /* .onboardingSlideHeader {
    font-family: Inter;
    font-size: 22px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36 !important;
    letter-spacing: -0.3px !important;
    text-align: center;
    color: var(--white);
  } */
  /* .onboardingSlideText {
    font-family: Inter;
    font-size: 17px !important;
    font-weight: var(--accessibility-bold-text);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53 !important;
    letter-spacing: -0.3px !important;
    text-align: center;
    color: var(--white);
  } */

  .onboardingSlide {
    padding: 20px 16px !important;
    width: calc(100vw - 40px) !important;
    height: auto !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    border-radius: 4px;
  }

  .onboardingSlideImage {
    max-width: 250px !important;
    width: 250px !important;
    max-height: 250px !important;
    height: 250px !important;
  }
}