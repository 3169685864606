.profileSettings {
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.05);
  background-color: var(--white);
  margin-bottom: 24px;
}

.profileSettingsTitleContainer {
  min-height: 56px;
  padding-left: 14px;
  display: flex;
  align-items: center;
  box-shadow: inset 0 -1px 0 0 #ced4da;
}

.profileSettingsTitle {
  font-size: calc(17px + var(--accessibility-font-size));
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.2px;
  color: var(--grey-80);
}

.profileSettingsButton {
  display: flex;
  justify-content: center !important;
  --background: transparent !important;
  --border-width: 0 !important;
  box-shadow: inset 0 -1px 0 0 #ced4da;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  --inner-padding-end: 0px !important;
  --inner-padding-end: 0 !important;
  padding-right: 0px !important;
  --ion-safe-area-left: 0px !important;
  --ion-safe-area-right: 0px !important;
}

.profileSettingsButton:last-child {
  box-shadow: unset !important;
}

.profileSettingsButton button {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.profileSettingsButtonInnerContainer {
  display: flex;
  flex: -1;
  align-items: center;
  justify-content: flex-start !important;
}

.profileSettingsButtonContainer {
  min-height: 60px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 0px !important;
}

.profileSettingButtonTitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: calc(17px + var(--accessibility-font-size)) !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.2px;
  color: var(--grey-90) !important;
  pointer-events: none !important;
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.profileSettingLogout {
  color: var(--cta-red) !important;
}

.HCPCProfileCard {
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.05);
  background-color: var(--white);
  margin-bottom: 16px;
}

.HCPCProfileCardTitleContainer {
  min-height: 78px;
  padding-left: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  box-shadow: inset 0 -1px 0 0 #ced4da;
}

.HCPCProfileCardTitle {
  font-size: calc(17px + var(--accessibility-font-size));
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.2px;
  color: var(--grey-80);
}

.HCPCProfileCardSubtitle {
  font-size: calc(13px + var(--accessibility-font-size));
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.1px;
  color: var(--grey-90);
  margin-top: 2px;
}

.HCPCSectionContainer {
  padding: 16px 14px;
  box-shadow: inset 0 -1px 0 0 #ced4da;
  display: flex;
  flex-direction: column;
  position: relative !important;
}

.HCPCExportContainerInner {
  padding: 10px 8px 10px 14px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.HCPCSectionContainer:last-child {
  box-shadow: unset !important;
}

.HCPCSectionContainerFilled {
  padding-right: 72px !important;
}

.HCPCSectionContainerFilledEvidence {
  padding-right: 78px !important;
}

.HCPCExportContainer {
  /* padding: 10px 8px 10px 14px; */
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  --background: transparent !important;
  --border-width: 0 !important;
  box-shadow: none !important;
  --inner-box-shadow: unset !important;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  --inner-padding-end: 0px !important;
  --inner-padding-end: 0 !important;
  --inner-border-width: 0 !important;
  padding-right: 0px !important;
  --ion-safe-area-left: 0px !important;
  --ion-safe-area-right: 0px !important;
  opacity: 1 !important;
}

.HCPCExportTitle {
  font-size: calc(17px + var(--accessibility-font-size));
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.2px;
  color: var(--grey-90);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.HCPCSectionTitle {
  font-size: calc(17px + var(--accessibility-font-size));
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.2px;
  color: var(--grey-90);
  margin-bottom: 4px;
}

.HCPCSectionDescription {
  font-size: calc(16px + var(--accessibility-font-size));
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.2px;
  color: var(--grey-90);
}

.HCPCSectionGetStartedButton {
  /* Container */
  width: auto;
  min-height: 28px !important;
  max-height: 28px !important;
  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
  --border-radius: 6px;
  border-radius: 6px;
  background-color: var(--cta-blue);
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  /* Text */
  font-size: calc(13px + var(--accessibility-font-size));
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.05px;
  text-align: center;
  color: var(--white);
}

.HCPCSectionEditButton {
  /* Container */
  width: auto;
  /* height: fit-content !important; */
  /* min-width: 44px; */
  height: 28px;
  min-height: 28px !important;
  max-height: 28px !important;
  /* max-width: 64px; */
  /* height: max-content !important; */
  max-height: 38px;
  border-radius: 6px;
  --border-radius: 6px;
  background-color: var(--grey-20) !important;
  --background: var(--grey-20);
  --background-hover: var(--grey-40);
  --background-activated: var(--grey-50);
  --background-focused: var(--grey-50);
  position: absolute;
  top: 14px;
  right: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  /* Text */
  font-size: calc(13px + var(--accessibility-font-size));
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.05px;
  text-align: center;
  color: var(--cta-blue);
  --color: var(--cta-blue) !important;
}

.HCPCSectionViewButton {
  min-width: 50px !important;
  max-width: 76px !important;
  min-height: 28px !important;
  max-height: 28px !important;
}

.wordCount {
  font-size: calc(12px + var(--accessibility-font-size));
  font-weight: var(--accessibility-bold-text-500);
  font-stretch: normal;
  font-style: normal;
  line-height: var((--accessibility-line-height));
  letter-spacing: -0.1px;
  color: var(--grey-70);
}

.HCPCWordCount {
  font-size: calc(12px + var(--accessibility-font-size));
  font-weight: var(--accessibility-bold-text-500);
  font-stretch: normal;
  font-style: normal;
  line-height: var((--accessibility-line-height));
  letter-spacing: -0.1px;
  color: var(--grey-70);
}

.summaryOfWorkTipContainer {
  border-radius: 2px;
  background-color: var(--grey-20);
  margin-top: -14px;
  padding-bottom: 16px;
}

.summaryOfWorkTipHeader {
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  margin-left: 2px;
  margin-right: 16px;
  align-items: center;
  justify-content: space-between;
}

.summaryOfWorkTipHeaderInner {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.summaryOfWorkTipIcon {
  width: 44px;
  height: 44px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.summaryOfWorkTipTitle {
  font-size: 17px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.2px;
  color: var(--grey-90);
}

.summaryOfWorkTipClose {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: flex-end;
}

.summaryOfWorkTipCloseText {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.25px;
  text-align: right;
  color: var(--cta-blue);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.summaryOfWorkTipText {
  font-size: 16px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: var(--grey-90);
  margin-left: 16px;
  margin-right: 16px;
}

.tox-tinymce--toolbar-sticky-off {
  position: fixed !important;
  top: calc((var(--ion-safe-area-top, 0px)) - calc(var(--ion-statusbar-padding, 0px) / 2) + 76px) !important;
  margin-left: -4px !important;
}

.summaryContainer {
  display: flex;
  min-height: calc(100vh - 154px);
  width: 100% !important;
  flex-direction: column;
}

.summaryEditorContainer {
  display: flex;
  flex: 1;
  background-color: black;
}

#personalStatementHeader {
  background-color: black !important;
  margin-top: 50px !important;
}

#summaryOfWorkEditorRef {
  display: flex;
  width: 100% !important;
  height: 100% !important;
  flex-direction: column;
  flex: 1;
  color: var(--black) !important;
}

#summaryOfWorkEditorRef p {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

#personalStatementTipModal {
  --width: 326px !important;
  --height: 440px !important;
  --border-radius: 10px !important;
  background-color: rgba(0, 0, 0, 0.4);
}

#accountDeletionModal {
  --width: 326px !important;
  --height: 450px !important;
  --border-radius: 10px !important;
  background-color: rgba(0, 0, 0, 0.4);
}

ion-toast {
  transform: translateY(-64px);
}

.ios .exportToast {
  transform: translateY(-50px) !important;
}

.exportToast {
  padding-bottom: 68px !important;
}

#summaryOfWorkTipModal {
  --width: 326px !important;
  --height: 350px !important;
  --border-radius: 10px !important;
  background-color: rgba(0, 0, 0, 0.4);
}

.tipModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100% !important;
}

.HARTModalTitle {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.1px;
  color: var(--grey-90);
  margin-top: 20px !important;
  margin-bottom: 0px;
  margin-left: 16px;
  margin-right: 16px;
}

.tipModalTitle {
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.1px;
  color: var(--grey-90);
  text-align: center;
  margin-top: 24px !important;
}

.tipModalText {
  font-size: 16px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: var(--grey-90);
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
}

.tipModalText a {
  color: var(--cta-blue);
  text-decoration: underline;
}

.tipBottomButtonContainer {
  background-color: black;
  border-top-width: 1px !important;
  border-top-style: solid;
  border-top-color: #dee2e6 !important;
}

.tipBottomButton {
  /* Container */
  height: 48px !important;
  width: 100% !important;
  background-color: var(--white);
  /* Text */

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.25px;
  color: var(--cta-blue);
}

.tipBottomButtonDestructive {
  color: var(--cta-red) !important;
}

.accountCardInner {
  position: relative;
  max-width: 980px;
  width: 100%;
  padding: 16px;
  background-color: var(--white);
  margin-bottom: 8px;
  padding-left: calc(16px + var(--ion-safe-area-left));
  padding-right: calc(16px + var(--ion-safe-area-right));
}

.accountCardTitle {
  font-size: calc(17px + var(--accessibility-font-size)) !important;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.2px;
  color: var(--grey-80);
}

.accountCardSubTitle {
  font-size: calc(13px + var(--accessibility-font-size)) !important;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.2px;
  color: var(--grey-70);
}

.accountCardContent {
  margin-top: 8px;
}

.accountButton {
  width: 100%;
  height: 50px;
  max-width: 358px !important;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--cta-blue);
  flex-shrink: 0;
  align-self: center;
  margin-top: 16px;
  --background: var(--grey-10);
  --background-hover: var(--grey-40);
  --background-activated: var(--grey-50);
  --background-focused: var(--grey-50);
  --border-radius: 10px !important;
}

.accountButtonTextLogout {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--grey-80);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor !important;
}

.accountButtonInner {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.accountButtonIcon {
  height: 14px;
  width: 14px;
  margin-right: 9px !important;
}

.accountButtonText {
  font-size: 16px;
  /* font-size: calc(16px + var(--accessibility-font-size)) !important; */
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--cta-blue);
}

.accountButtonTextRequest {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--cta-red);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.accountDeletionAlert .alert-wrapper {
  --border-radius: 10px !important;
  border-radius: 10px !important;
}

.accountDeletionAlert .alert-title {
  font-size: 20px !important;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.1px;
  color: var(--grey-90);
  text-align: center;
}

.accountDeletionAlert .alert-message {
  font-size: 16px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: var(--grey-90);
}

.accountDeletionAlert .alert-button-inner {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.25px;
  color: var(--cta-blue);
}

.accessibilityTextPreviewContainerInner {
  position: relative;
  max-width: 980px;
  width: 100%;
  margin: 16px 16px 8px 16px;
  padding: 12px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.05);
  background-color: var(--white);
}

.accessibilityTextPreviewContainer {
  display: flex;
  justify-content: center;
}

.accessibilityTextPreviewText {
  font-size: calc(17px + var(--accessibility-font-size));
  /* font-weight:  var(--accessibility-bold-text); */
  font-weight: normal bold;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.3px;
  color: var(--grey-90);
}

.accessibilitySection {
  display: flex;
  justify-content: center;
}

.accessibilitySectionInner {
  position: relative;
  max-width: 980px;
  width: 100%;
}

.accessibilitySectionTitleContainer {
  padding: 8px 16px 8px 16px;
}

.accessibilitySectionTitle {
  font-size: calc(13px + var(--accessibility-font-size));
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: normal;
  color: var(--grey-70);
}

.accessibilitySectionContent {
  background-color: var(--white);
}

.accessibilitySectionButton {
  display: flex;
  justify-content: center !important;
  --background: transparent !important;
  --border-width: 0 !important;
  box-shadow: inset 0 -1px 0 0 #ced4da;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  --inner-padding-end: 0px !important;
  --inner-padding-end: 0 !important;
  padding-right: 0px !important;
  --ion-safe-area-left: 0px !important;
  --ion-safe-area-right: 0px !important;
  opacity: 1 !important;
}

.accessibilitySectionPercentageButtonReset {
  display: flex;
  flex: 1;
  height: 52px;
  width: calc(100% + 12px) !important;
  /* margin-left: -1.5px;
  margin-right: -1.5px; */
  justify-content: center !important;
  --background: transparent !important;
  --border-width: 0 !important;
  box-shadow: unset !important;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  --inner-padding-end: 0px !important;
  --inner-padding-end: 0 !important;
  padding-top: 0 !important;
  --inner-padding-top: 0 !important;
  padding-bottom: 0 !important;
  --inner-padding-bottom: 0 !important;
  padding-right: 0px !important;
  --ion-safe-area-left: 0px !important;
  --ion-safe-area-right: 0px !important;
  opacity: 1 !important;
}

.accessibilitySectionButton:last-child {
  box-shadow: unset !important;
  --border-color: transparent !important;
}

.accessibilitySectionButtonContainer {
  height: calc(52px + var(--accessibility-font-size)) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.accessibilitySectionButton ion-label {
  font-size: calc(17px + var(--accessibility-font-size)) !important;
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.accessibilitySectionButtonInnerContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between !important;
}

.accessibilitySectionButtonTitle {
  font-size: calc(17px + var(--accessibility-font-size)) !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.2px;
  color: var(--grey-90);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.accessibilitySectionButtonTitleInter {
  font-family: Inter !important;
  font-size: calc(17px + var(--accessibility-font-size));
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.2px;
  color: var(--grey-90);
}

.accessibilityFontFamilyDyslexic {
  font-family: "Open Dyslexic" !important;
  font-weight: bold !important;
}

.accessibilitySettingsTick {
  width: 44px;
  height: 44px;
  margin-right: -7.5px;
}

.accessibilitySectionButtonsRow {
  display: flex;
  flex-direction: row;
  border-bottom-width: 1px;
  border-bottom-color: #ced4da !important;
  border-bottom-style: solid;
  height: 52px;
}

.accessibilitySectionPercentageTextPlaceholder {
  /* Container */
  display: flex;
  flex: 1;
  width: 100%;
  height: 52px;
  /* margin-left: 1.5px; */
  /* margin-right: 1.5px; */
  justify-content: center;
  align-items: center;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-color: #ced4da !important;
  border-style: solid;
  /* Text */

  font-size: 17px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  text-align: center;
  color: var(--grey-70);
}

.accessibilitySectionPercentageText {
  /* Container */
  display: flex;
  flex: 1;
  width: 100%;
  height: 52px;
  justify-content: center;
  align-items: center;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-color: #ced4da !important;
  border-style: solid;
  /* Text */

  font-size: 17px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  text-align: center;
  color: var(--grey-70);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.accessibilitySectionPercentageTextReset {
  /* Container */
  display: flex;
  flex: 1;
  width: 100%;
  height: 52px;
  justify-content: center;
  align-items: center;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-color: #ced4da !important;
  border-style: solid;
  /* Text */

  font-size: 14px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  text-align: center;
  color: var(--grey-70);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.accessibilitySectionPercentageButton {
  display: flex;
  flex: 1;
  justify-content: center !important;
  --background: transparent !important;
  --border-width: 0 !important;
  box-shadow: unset !important;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  --inner-padding-end: 0px !important;
  --inner-padding-end: 0 !important;
  padding-right: 0px !important;
  --ion-safe-area-left: 0px !important;
  --ion-safe-area-right: 0px !important;
  height: 52px !important;
}

.accessibilitySectionPercentageButtonInner {
  height: 52px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.accessibilitySectionPercentageIcon {
  width: 44px;
  height: 44px;
}

#summaryOfWorkModal {
  --border-radius: 0px !important;
}

#summaryOfWorkModal ion-toolbar {
  padding-top: max(var(--ion-safe-area-top), var(--ion-statusbar-padding)) !important;
}

@media (max-width: 450px) {
  .profileSettingButtonTitle {
    max-width: 220px !important;
  }
}
