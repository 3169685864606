.reflectionSaveFooter {
  display: flex;
  justify-content: flex-end;
  background-color: var(--white);
  box-shadow: inset 0 0.5px 0 0 #adb5bd;
  background-color: var(--white);
  /* height: calc(60px + var(--ion-safe-area-bottom)) !important; */
}

.programDetailsFooter {
  display: flex;
  justify-content: center;
  background-color: var(--white);
  box-shadow: inset 0 0.5px 0 0 #adb5bd;
  background-color: var(--white);
  /* height: calc(60px + var(--ion-safe-area-bottom)) !important; */
  padding-bottom: env(safe-area-inset-bottom) !important;
}

.programDetailsSaveContainer {
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: center;
  width: 100%;
  max-width: 980px;
  padding-left: calc(16px + var(--ion-safe-area-left));
  padding-right: calc(16px + var(--ion-safe-area-right));
}

.programDetailsSaveButton {
  width: 100%;
  height: 50px;
  max-width: 375px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--white);
  flex-shrink: 0;
  --border-radius: 8px;
}

#changeTaskingTypeModal,
#changeProgressCheckModal {
  --width: 280px !important;
  --height: 240px !important;
  --border-radius: 10px !important;
  background-color: rgba(0, 0, 0, 0.4);
}

.custom-progress-check-button {
  --background: transparent !important;
  --border-width: 0 !important;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  --inner-padding-end: 0px !important;
  --inner-padding-end: 0 !important;
  --ion-safe-area-left: 0px !important;
  --ion-safe-area-right: 0px !important;
}

.changeTaskingTypeModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100% !important;
}

.changeTaskingTypeModalTitle {
  font-family: Inter;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.1px;
  text-align: center;
  color: var(--grey-90);
  margin-top: 18px !important;
}

.changeTaskingTypeModalText {
  font-family: Inter;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
  text-align: center;
  color: var(--grey-90);
  margin-top: 8px;
}

.reflectionSaveContainer {
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: center;
  width: 100%;
  max-width: 980px;
  padding-left: calc(16px + var(--ion-safe-area-left));
  padding-right: calc(16px + var(--ion-safe-area-right));
}

.reflectionUploadingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.reflectionUploadingAttachmentsSpinner {
  height: 24px !important;
  width: 24px !important;
  --color: var(--grey-90);
}

.reflectionUploadingText {
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
  text-align: center;
  color: var(--grey-90);
  margin-left: 8px;
}

.reflectionSaveSpinner {
  height: 24px;
  width: 24px;
  --color: var(--white);
  margin-top: -4px;
}

.reflectionSaveSpinnerDraft {
  height: 24px;
  width: 24px;
  --color: var(--cta-blue);
}

.reflectionSaveButton {
  width: 100px;
  height: 44px;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--white);
  margin-left: 8px;
  flex-shrink: 0;
  overflow: hidden;
}

.reflectionSaveButtonOutline {
  color: var(--cta-blue);
  margin-left: 16px !important;
  border-color: var(--cta-blue);
  --border-color: var(--cta-blue);
  border-width: 1px;
  --border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  --border-radius: 10px;
  --background: var(--white);
  --background-hover: var(--grey-40) !important;
  --background-activated: var(--grey-50) !important;
  --background-focused: var(--grey-50) !important;
}

.reflectionContentCard {
  padding: 14px;
  border-radius: 6px;
  border: solid 1px var(--grey-40);
  background-color: var(--white);
  margin-bottom: 16px;
}

.reflectionContentCardTitle {
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: var(--grey-70);
  margin-bottom: 4px;
}

.reflectionTitleInput {
  /* Container */
  width: 100%;
  /* height: 34px; */
  border: 0 !important;
  background-color: var(--white);
  margin-left: -2.5px;
  /* Text */

  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -0.4px;
  color: #000000;
  --placeholder-color: var(--grey-60);
}

.reflectionTitleInput::placeholder {
  color: var(--grey-60);
}

ion-modal.stack-modal {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

#addAttachmentsModal {
  --width: 364px !important;
  --height: 364px !important;
  background-color: rgba(0, 0, 0, 0.4);
}

.addAttachmentsContent {
  display: flex;
  background-color: var(--white);
  padding: 24px 16px;
  border-radius: 10px !important;
}

.addAttachmentsBorder {
  height: calc(100% - 20px);
  display: flex;
  border-color: var(--cta-blue);
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-width: 4px;
  border-style: dashed;
  cursor: pointer;
}

.addAttachmentsCTAText {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.1px;
  color: var(--cta-blue);
  text-align: center;
}

.reflectionTagsInput {
  /* Container */
  width: 100%;
  height: 28px;
  border: 0 !important;
  background-color: var(--white);
  margin-left: -2.5px;
  /* Text */

  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.1px;
  color: #000000;
}

.reflectionTagsInput::placeholder {
  color: var(--grey-60);
}

.evidenceRolesSelectPlaceholder {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.1px;
  color: var(--grey-60);
}

.standardTagsPopover {
  position: absolute;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-height: 260px !important;
  border-radius: 6px;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--grey-50);
  background-color: var(--white);
  z-index: 10;
  overflow: auto;
  transform: translateY(4px);
  display: flex;
  flex-direction: column;
  overflow-x: auto !important;
}

.standardTagPopoverEmpty {
  padding: 14px;
}

.standardTagPopoverEmptyText {
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.2px;
  color: var(--grey-90);
  /* text-align: center; */
}

.standardTagHeader {
  /* Container */
  height: 24px;
  /* border-radius: 6px; */
  border: solid 1px var(--grey-50);
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  background-color: var(--grey-20);
  display: flex;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  /* Text */

  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: var(--grey-90);
}

.standardTagButton {
  /* Container */
  min-height: 52px;
  width: calc(100% - 36px);
  box-shadow: inset 0 -1px 0 0 #ced4da;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-left: 18px;
  cursor: pointer;
  /* Text */

  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.2px;
  color: var(--grey-90);
  padding-top: 12px;
  padding-bottom: 12px;
  /* white-space: nowrap;/ */
  /* overflow: hidden; */
  /* text-overflow: clip; */
}

.standardTagButtonLast {
  box-shadow: unset !important;
}

.reflectionDateContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  max-width: 300px;
}

.reflectionDateText {
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  color: var(--grey-90);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.reflectionActivityTypeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.reflectionActivityTypeText {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.1px;
  color: var(--grey-90);
}

.reflectionActivityTypeTextPlaceholder {
  color: var(--grey-60);
}

.reflectionDocumentsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.reflectionDocumentsAddButton {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

.reflectionDocumentsAddText {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.1px;
  color: var(--cta-blue);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.reflectionDocumentsAddIcon {
  width: 44px;
  height: 44px;
  margin-left: -3.5px;
  pointer-events: none;
}

#reflectionStandardTagsInput {
  min-width: 260px;
}

#reflectionCustomTagsInput {
  min-width: 350px;
}

.reflectionsCustomTagContainer,
.reflectionsStandardTagsContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.reflectionTagContainer {
  height: 32px;
  padding: 2px 2px 2px 8px;
  border-radius: 6px;
  background-color: var(--grey-20);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.reflectionTagText {
  font-size: 20px;
  font-weight: var(--accessibility-bold-text-500);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.3px;
  color: var(--grey-90);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.attachmentContainer {
  height: 32px;
  max-width: 100%;
  padding: 2px 2px 2px 4px;
  border-radius: 6px;
  border: solid 1px var(--grey-30);
  background-color: var(--white);
  display: flex;
  align-items: center;
}

.attachmentInnerContainerLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.attachmentInnerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.attachmentIconSpinnerContainer {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attachmentIconSpinner {
  width: 14px;
  height: 14px;
}

.attachmentIcon {
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.attachmentIconCloseContainer {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.attachmentIconClose {
  width: 28px;
  height: 28px;
  pointer-events: none;
}

.attachmentFilename {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: inline-flex;

  flex-shrink: 1;
  font-size: 20px;
  font-weight: var(--accessibility-bold-text-500);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.3px;
  color: var(--grey-90);
  margin-left: 2px;
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.RichEditor-editor {
  background: #fff;
  border: 1px solid #ddd;
  font-family: "Georgia", serif;
  font-size: 14px;
  padding: 15px;
}

.tox-tinymce {
  margin-top: 16px !important;
}

.tox-notification {
  display: none !important;
}

/* .tox-notification,
.tox-silver-sink,
.tox-tinymce-aux {
  display: none !important;
} */

.appInfoHeader {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -0.4px;
  color: var(--grey-90);
  margin-bottom: 10px;
}

.appInfo p {
  font-size: 15px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
  color: var(--grey-90);
}

.appInfo a {
  color: var(--cta-blue);
  font-weight: var(--accessibility-bold-text-500);
}

.reflectionAuditCheckboxButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -10px;
  cursor: pointer;
  margin-bottom: 24px;
}

.reflectionAuditCheckbox {
  height: 44px;
  width: 44px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}

.reflectionAuditCheckboxText {
  font-size: 17px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  color: var(--grey-90);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.mce-btn-group .mce-btn {
  float: left !important;
}

#evidenceModal ion-toolbar {
  padding-top: max(var(--ion-safe-area-top), var(--ion-statusbar-padding)) !important;
}

#evidenceModal input {
  padding: 0 !important;
}

.programTrustPill {
  /* Container */
  padding: 0 4px;
  border-radius: 2px;
  display: inline-flex;
  /* Text */
  font-family: var(--ion-font-family);
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.05px;
  color: var(--white);
  background-color: #72a7e9;
}

.programTrustPillStarted {
  margin-bottom: 4px !important;
  margin-top: -6px !important;
}

.programCardTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.programCardTitle {
  font-family: var(--ion-font-family);
  font-size: calc(17px + var(--accessibility-font-size)) !important;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.2px;
  color: var(--cta-blue);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.programCardSubtitle {
  font-family: Inter;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.3px;
  color: var(--white);
  margin-top: 4px;
}

.programGetStartedButton {
  width: 100%;
  max-width: 350px !important;
  height: 52px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  align-self: center;
  color: #c05621;
  margin-top: 32px !important;
  margin-bottom: -1px;
  --border-radius: 8px;
  --background: var(--white);
  --background-hover: var(--grey-40);
  --background-activated: var(--grey-50);
  --background-focused: var(--grey-50);
  align-self: center;
  justify-content: center;
}

.programCardTitleDisclosure {
  height: 44px;
  width: 44px;
  margin-right: -14px;
}

.programCardRequiredSkillContainer {
  flex-direction: column;
}

.programCardRequiredSkillResult {
  display: flex;
  flex-direction: row !important;
  flex-shrink: -1;
  height: 44px;
  flex-direction: row;
  align-items: flex-end;
}

.programCardRequiredSkillAchieved {
  font-size: calc(34px + var(--accessibility-font-size)) !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  color: var(--grey-60);
}

.programCardRequiredSkillAchievedGreen {
  color: #06a14f !important;
}

.programCardRequiredSkillNeeded {
  font-size: calc(15px + var(--accessibility-font-size)) !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: var(--grey-60);
  margin-bottom: 5px;
}

.programCardRequiredGraph {
  display: flex;
  height: 4px;
  flex: 1;
  align-self: flex-end;
  display: flex;
  border-radius: 2px;
  background-color: transparent;
  margin-bottom: 7px;
  overflow: hidden;
}

.programSegmentContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.programSegmentFilled {
  height: 4px;
  background-color: #09ab55;
  display: flex;
  flex-grow: 1;
  border-radius: 2px;
}

.programSegmentEmpty {
  height: 4px;
  background-color: var(--grey-30);
  display: flex;
  flex-grow: 1;
  border-radius: 2px;
}

.programGraphSeparator {
  width: 4px;
  height: 4px;
  background-color: transparent;
}

.programCertificateAddText {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: var(--cta-blue);
}

.detailsRoleCheckboxContainer {
  --min-height: 58px;
  box-shadow: inset 0 -1px 0 0 #dee2e6;
  background-color: var(--white) !important;
  --inner-box-shadow: 0 !important;
  --inner-padding-top: 0 !important;
  --inner-padding-end: 0 !important;
  --padding-start: 0;
  --padding-end: 0;
  --background: var(--white);
  --border-color: #dee2e6 !important;
  cursor: pointer;
}

.radioItem {
  box-shadow: inset 0 -1px 0 0 #dee2e6;
  --min-height: 58px;
  --inner-box-shadow: 0 !important;
  --inner-padding-top: 0 !important;
  --inner-padding-end: 0 !important;
  --padding-start: 0;
  --padding-end: 0;
  --background: var(--white);
  --border-color: #dee2e6 !important;
}

.removeItemPadding {
  --inner-box-shadow: 0 !important;
  --inner-padding-top: 0 !important;
  --inner-padding-end: 0 !important;
  --padding-start: 0;
  --padding-end: 0;
}

.checkboxItem {
  box-shadow: inset 0 -1px 0 0 #dee2e6;
  --inner-box-shadow: 0 !important;
  --inner-padding-top: 0 !important;
  --inner-padding-end: 0 !important;
  --padding-start: 0;
  --padding-end: 0;
  --background: var(--white);
  --border-color: #dee2e6 !important;
}

.programCardInfoHeader {
  margin-top: 8px;
  margin-bottom: 4px;
}

.programCardInfoHeaderText {
  font-family: Inter;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.1px;
  color: var(--grey-70);
}

.programCardInfoText {
  font-family: Inter;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  color: var(--grey-90);
}

.programCardInfoHeaderCount {
  font-family: Inter;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -0.4px;
  color: var(--grey-90);
}

.programCardInfoHeaderBold {
  font-family: Inter;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  color: var(--grey-90);
}

.programCardInfoHeaderLink {
  font-family: Inter;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  color: var(--cta-blue);
}

.programCardInfoEvidenceCount {
  font-family: Inter;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -0.4px;
  color: var(--grey-90);
}

.programCertificateButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.programEnhancedSkillButton {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  box-shadow: none !important;
  --border-color: #ffffff !important;
  --padding-start: 0px !important;
  --inner-padding-start: 0 !important;
  /* padding-top: 16px; */
  /* padding-bottom: 12px; */
  /* padding-right: 4px; */
  padding-left: 0px !important;
}

.programEnhancedSkillButton::part(native) {
  padding-left: 0px !important;
  padding-right: 0px !important;
  --inner-padding-start: 0px !important;
  --inner-padding-end: 0px !important;
  --padding-end: 0px !important;
}

.programCertificateExpiredText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: var(--cta-red);
  margin-top: 14px;
}

.programCardCertificatePillGreen {
  /* Container */
  flex-shrink: 0;
  height: 20px;
  padding: 0 8px;
  border-radius: 10px;
  background-color: #c6f7c1;
  /* Text */
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.05px;
  text-align: right;
  color: #085333;
}

.programCardCertificatePillAmber {
  /* Container */
  flex-shrink: 0;
  height: 20px;
  padding: 0 8px;
  border-radius: 10px;
  background-color: #feebc8;
  /* Text */
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.05px;
  text-align: right;
  color: #9c4221;
}

.programCardCertificatePillExpired {
  /* Container */
  flex-shrink: 0;
  height: 20px;
  padding: 0 8px;
  border-radius: 10px;
  background-color: var(--cta-red);
  /* Text */
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.05px;
  text-align: right;
  color: var(--white);
}

.programCardCertificatePillBlank {
  /* Container */
  height: 20px;
  padding: 0 8px;
  border-radius: 10px;
  background-color: transparent;
  /* Text */
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.05px;
  text-align: right;
  color: var(--cta-blue);
}

.programCardCertificatePillNone {
  /* Container */
  height: 20px;
  padding: 0 8px;
  border-radius: 10px;
  background-color: #fcbaac;
  /* Text */
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.05px;
  text-align: right;
  color: #890012;
}

.programPlaceholderCard {
  background-color: #dd6b20;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.programPlaceholderCardInner {
  padding: 16px 14px;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.programPlaceholderCardBackground {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.programCardBackgroundHART {
  background: url("../../assets/images/Module_Pattern_HART@1x.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% + 150px) !important;
  background-position-y: -25px;
}

.programCardBackgroundMTS {
  background: url("../../assets/images/Module_Pattern_MTS_Transparent.svg") no-repeat center center fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.programCardBackgroundCHUB {
  background: url("../../assets/images/Module_Pattern_CHUB_Transparent.svg") no-repeat center center fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.programCardBackgroundCSM {
  background: url("../../assets/images/Module_Pattern_CSM_Transparent.svg") no-repeat center center fixed !important;
  background-repeat: no-repeat !important;
  background-position: right -50px center !important;
}

.programCardBackgroundSWASTHARTandEPRRInstructors {
  background: url("../../assets/images/Module_Pattern_HARTEPRR_Card_Transparent@1x.svg") no-repeat center center fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.programCardBackgroundPreceptee {
  background: url("../../assets/images/Module_Pattern_Preceptor@3x.svg");
  background-repeat: no-repeat !important;
  background-position-x: 100% !important;
}

.programCardBackgroundNWASEPRREvidence {
  background: url("../../assets/images/Module_Pattern_IC_Transparent@1x.svg");
  background-repeat: no-repeat !important;
  background-position-x: 100% !important;
}

.programCard {
  --background: var(--white);
  --border-color: #ffffff !important;
  --padding-start: 14px !important;
  --inner-padding-start: 0 !important;
  --inner-padding-end: 0 !important;
  --padding-end: 0 !important;
  padding: 0 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.programCard::part(native) .item-inner {
  padding-right: 0px !important;
}

.programCard::part(native) {
  padding-left: 14px !important;
  padding-right: 14px !important;
  --inner-padding-start: 0 !important;
  --inner-padding-end: 0 !important;
  --padding-end: 0 !important;
}

.programCardInner {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  padding-top: 13px;
  padding-bottom: 12px;
  padding-right: 4px;
  padding-left: 0px;
}

.programCertificateDisclosureContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.programCardCertificateDisclosure {
  height: 24px;
  width: 24px;
}

.techniquesHeaderContainer {
  min-height: 44px;
  box-shadow: inset 0 -1px 0 0 #dee2e6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.techniqueEvidenceCountContainer {
  min-height: 44px;
  box-shadow: inset 0 -1px 0 0 #dee2e6;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.programCardContent {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.programCardButton {
  width: 100%;
  height: 50px;
  max-width: 358px !important;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--cta-blue);
  flex-shrink: 0;
  margin-top: 16px;
  --border-radius: 8px !important;
  --background: var(--grey-10);
  --background-hover: var(--grey-40);
  --background-activated: var(--grey-50);
  --background-focused: var(--grey-50);
}

.programCardButton[disabled] {
  opacity: 1 !important;
}

.accountButtonInner .exportingEvidenceSpinner {
  color: var(--cta-blue) !important;
}

.infoModalContainer h1 {
  font-family: Inter;
  font-size: calc(20px + var(--accessibility-font-size)) !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.1px;
  color: var(--grey-90);
  margin-top: 0px !important;
}

.infoModalContainer p {
  font-family: var(--ion-font-family);
  font-size: calc(16px + var(--accessibility-font-size)) !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.2px;
  color: var(--grey-90);
  margin-top: 16px;
  margin-bottom: 16px;
}

.evidenceHeaderContainer {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
}

.evidenceHeaderText {
  font-family: Inter;
  font-size: 12px;
  font-weight: var(--accessibility-bold-text-600);
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: normal;
  color: var(--grey-60);
}

@media (max-width: 500px) {
  .reflectionContentCardTitle {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--grey-70);
    margin-bottom: 2px;
  }

  .reflectionTitleInput {
    /* Container */
    width: 100%;
    height: 38px;
    border: 0 !important;
    background-color: var(--white);
    margin-left: -2.5px;
    /* Text */

    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: -0.3px;
    color: #000000;
  }

  .reflectionTagsInput {
    /* Container */
    width: 100%;
    height: 28px;
    border: 0 !important;
    background-color: var(--white);
    margin-left: -2.5px;
    /* Text */

    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: -0.3px;
    color: #000000;
  }

  #reflectionCustomTagsInput {
    min-width: 285px;
  }

  .reflectionDocumentsAddText {
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: -0.3px;
    color: var(--cta-blue);
    text-decoration: var(--accessibility-underline-links);
    text-decoration-color: currentColor;
  }
}
