.newsCardContainer {
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
}

.newsCardImageContainer {
}

.newsCardInfoContainer {
  margin-right: 16px !important;
}

.newsCardDate {
  opacity: 0.7;

  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: var(--grey-90);
}

.newsCardTitle {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.1px;
  color: var(--grey-90);
}

.newsCardSubtitle {
  opacity: 0.8;

  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.05px;
  color: var(--grey-90);
}

@media (max-width: 550px) {
  .newsCardImageContainer {
    margin: auto !important;
  }

  .newsCardContainer {
    flex-direction: column !important;
  }

  .newsCardInfoContainer {
    margin-right: 0px !important;
    margin-top: 8px !important;
  }
}

@media (min-width: 1080px) {
  .newsCardDate {
    font-size: 13px !important;
    line-height: 1.54 !important;
    letter-spacing: normal !important;
  }

  .newsCardTitle {
    font-size: 22px !important;
    line-height: 1.36 !important;
    letter-spacing: -0.3px !important;
  }

  .newsCardSubtitle {
    font-size: 15px !important;
    line-height: 1.33 !important;
    letter-spacing: -0.2px !important;
  }
}
