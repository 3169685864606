.mainMenu {
  /* z-index: 999999 !important; */
  /* box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.5) !important; */
}

.mainMenu::part(container) {
  /* box-shadow: 0px 0px 4px rgba(0, •0, 0, 0.5) !important; */
}

.menuContent {
  background-color: #f8f9fa !important;
  --background: #f8f9fa !important;
  box-shadow: none !important;
}

.menuShadow {
  width: 1px;
  height: 100%;
  position: absolute;
  right: -1px;
  background-color: transparent !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5) !important;
  z-index: 9999999 !important;
}

.menuFooter {
  padding-bottom: calc(20px + var(--accessibility-font-size) + env(safe-area-inset-bottom)) !important;
  background-color: #f8f9fa !important;
  box-shadow: none !important;
}

.menuFooter::before {
  display: none !important;
  background-color: #f8f9fa !important;
}

.menuFooterText {
  font-size: calc(11px + var(--accessibility-font-size)) !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  color: var(--grey-60);
}
