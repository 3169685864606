.HARTPlaceholderCard {
  padding: 16px 14px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.05);
  background-color: var(--HART-orange);
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.HARTCardSubtitle {
  font-family: Inter;
  font-size: calc(15px + var(--accessibility-font-size)) !important;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.3px;
  color: var(--white);
  margin-top: 4px;
}

.HARTGetStartedButton {
  width: 200px;
  height: 52px;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  align-self: center;
  color: var(--HART-orange-dark);
  margin-top: 32px !important;
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
  background-color: var(--white) !important;
  --background: var(--white);
  --background-hover: var(--grey-40);
  --background-activated: var(--grey-50);
  --background-focused: var(--grey-50);
  border-radius: 6px;
  --border-radius: 6px;
}

.dashboardCard {
  padding: 16px 14px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.05);
  background-color: var(--white);
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.dashboardInfoCard {
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.05);
  background-color: var(--white);
  margin-bottom: 16px;
}

.dashboardInfoCardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px 569px 14px 14px;
  box-shadow: inset 0 -1px 0 0 #ced4da;
}

.dashboardInfoCardTitle {
  margin-bottom: 0 !important;
}

.dashboardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dashboardCardTitleContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.dashboardCardSubtitle {
  font-size: calc(13px + var(--accessibility-font-size)) !important;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.1px;
  color: var(--grey-90);
}

.informationCardButton {
  display: flex;
  justify-content: center !important;
  --background: transparent !important;
  --border-width: 0 !important;
  box-shadow: inset 0 -1px 0 0 #ced4da !important;
  --border-color: #ced4da !important;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  --inner-padding-end: 0px !important;
  --inner-padding-end: 0 !important;
  padding-right: 0px !important;
  --ion-safe-area-left: 0px !important;
  --ion-safe-area-right: 0px !important;
}

.informationCardButton:last-child {
  box-shadow: none !important;
  --inner-box-shadow: unset !important;
  margin-bottom: -1px;
}

.informationCardButton button {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.dashboardCardTitle {
  font-size: calc(17px + var(--accessibility-font-size)) !important;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.2px;
  color: var(--grey-80);
}

.dashboardCardText {
  font-size: 17px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  color: var(--grey-90);
}

.dashboardCardText p {
  margin: 0;
  margin-bottom: 10px;
}

.dashboardCardText a {
  color: var(--cta-blue) !important;
}

.dashboardCardText p:last-child {
  margin: 0 !important;
}

.developmentProgressPill {
  /* Container */
  display: flex;
  height: 24px;
  padding: 5px 8px 5px 8px;
  border-radius: 14px;
  background-color: var(--orange-30);
  margin-left: 12px;
  margin-right: 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  pointer-events: none;
  /* Text */

  font-size: 11px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--yellow-90);
}

.newFeaturePill {
  background-color: #b2f5ea !important;
  color: #2a4365 !important;
}

.dashboardSideButton {
  /* Container */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 28px !important;
  max-height: 28px !important;
  /* padding: 4px 8px 4px 8px; */
  border-radius: 14px;
  --border-radius: 14px;
  background-color: var(--grey-20);
  --background: var(--grey-20);
  --background-hover: var(--grey-40);
  --background-activated: var(--grey-50);
  --background-focused: var(--grey-50);
  cursor: pointer;
  user-select: none;
  flex-shrink: 0;
  /* Text */
  font-size: calc(15px + var(--accessibility-font-size)) !important;
  /* font-size: 15px; */
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.2px;
  text-align: right;
  color: var(--cta-blue);
  margin-left: 8px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.dashboardSeeMoreButton {
  /* Container */
  display: flex;
  flex-grow: 0;
  justify-content: flex-start;
  align-items: center;
  min-height: 28px;
  /* background-color: var(--grey-20); */
  cursor: pointer;
  user-select: none;
  flex-shrink: 1;
  margin-top: 20px;
}

.dashboardSeeMoreButtonText {
  font-size: calc(17px + var(--accessibility-font-size)) !important;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.2px;
  color: var(--cta-blue);
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.overviewCardDetails {
  font-size: calc(17px + var(--accessibility-font-size)) !important;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.3px;
  color: var(--grey-90);
  margin-top: 4px;
  margin-bottom: 12px;
}

.nosCardDetails {
  font-size: calc(17px + var(--accessibility-font-size)) !important;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: -0.3px;
  color: var(--grey-90);
  margin-top: 4px;
  margin-bottom: 12px;
}

.overviewStandardsHeader {
  min-width: 160px;
  max-width: 160px;
  font-size: calc(13px + var(--accessibility-font-size)) !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: normal;
  color: var(--grey-70);
  margin-right: 20px;
}

.completedStandardsContainer {
  display: flex;
  flex-direction: row !important;
  flex-shrink: -1;
}

.completedStandardsCount {
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.completedStandards {
  font-size: calc(34px + var(--accessibility-font-size)) !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.5px;
  color: #019145;
}

.totalStandards {
  font-size: calc(15px + var(--accessibility-font-size)) !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: var(--grey-60);
  margin-bottom: 4px;
}

.completedStandardsGraphContainer {
  height: 16px;
  flex: 1;
  align-self: flex-end;
  display: flex;
  border-radius: 4px;
  background-color: var(--grey-30);
  margin-bottom: 7px;
  overflow: hidden;
}

.graphSegmentContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.graphFilledSegment {
  height: 16px;
  background-color: #09ab55;
  display: flex;
  flex-grow: 1;
}

.graphEmptySegment {
  height: 16px;
  display: flex;
  flex-grow: 1;
}

.graphSeparator {
  width: 2px;
  height: 16px;
  background-color: var(--white);
}

.overviewCardStandardsHeader {
  font-size: calc(22px + var(--accessibility-font-size)) !important;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: -0.3px;
  color: var(--grey-80);
  margin-top: 24px;
  margin-bottom: 8px;
}

.overviewCardStandardContainer {
  box-shadow: inset 0 -1px 0 0 #dee2e6;
}

.overviewCardStandardProgressContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  background-color: var(--white);
}

.overviewCardStandardGraphContainer {
  display: flex;
  flex: 1;
  /* width: 100%; */
  height: 20px;
  margin: 4px 40px;
  border-radius: 4px;
  background-color: var(--grey-20);
  overflow: hidden;
}

.overviewCardStandardCode {
  font-size: min(22px, calc(20px + var(--accessibility-font-size))) !important;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.1px;
  color: var(--grey-90);
  flex-shrink: -1;
  min-width: 120px;
}

.overviewCardStandardCount {
  font-size: min(22px, calc(20px + var(--accessibility-font-size))) !important;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.1px;
  color: var(--grey-90);
  flex-shrink: -1;
}

.overviewCardStandardDescription {
  font-size: calc(15px + var(--accessibility-font-size)) !important;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height) !important;
  letter-spacing: -0.3px;
  color: var(--grey-80);
  padding-bottom: 14px !important;
}

.informationCardButtonContainer {
  min-height: 72px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 0px !important;
}

.informationCardButtonInnerContainer {
  display: flex;
  /* flex-shrink: -1; */
  flex-grow: 1 !important;
  align-items: center;
  justify-content: flex-start !important;
  max-width: calc(100% - 53px) !important;
}

.informationCardButtonIcon {
  width: 44px;
  min-width: 44px;
  height: 44px;
  min-height: 44px;
  margin-left: 14px;
  margin-right: 10px;
  border-radius: 9.8px;
  background-color: var(--grey-20);
  overflow: hidden !important;
}

.informationCardButtonTitleContainer {
  min-width: 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.informationCardButtonTitle {
  font-size: calc(17px + var(--accessibility-font-size)) !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.2px;
  color: var(--grey-90) !important;
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.informationCardButtonSubtitle {
  font-size: calc(11px + var(--accessibility-font-size)) !important;
  font-weight: var(--accessibility-bold-text-500);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: var(--grey-60) !important;
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.evidenceOverviewGraph {
  margin-top: 12px;
}

.evidenceOverviewTotalContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;
}

.evidenceOverviewTotalTitle {
  font-size: calc(12px + var(--accessibility-font-size)) !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--grey-70);
}

.evidenceOverviewTotalValue {
  font-size: calc(28px + var(--accessibility-font-size)) !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -0.4px;
  color: var(--grey-90);
}

.evidenceOverviewTotalSeparator {
  width: 64px;
}

.evidenceOverviewAverageTitle {
  font-size: calc(12px + var(--accessibility-font-size)) !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #5a67d8;
}

.evidenceOverviewAverageValue {
  font-size: calc(28px + var(--accessibility-font-size)) !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -0.4px;
  color: #5a67d8;
}

.graphContainer {
  margin-top: 12px;
  height: 228px;
  width: 100% !important;
  justify-content: stretch !important;
  align-items: stretch !important;
}

.evidenceOverviewEmptyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: -9px !important;
}

.evidenceOverviewEmptyText {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--grey-60);
  margin-bottom: -16px;
}

.cpdEmptyText {
  margin-bottom: 10px !important;
}

.segmentContainer {
  --background: #f0f0f1 !important;
}

ion-segment-button {
  --color: #000000 !important;
  --indicator-color: var(--white) !important;
  --border-color: rgba(0, 0, 0, 0.12) !important;
}

@media (max-width: 500px) {
  .dashboardCard {
    padding: 16px 14px;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px rgba(0, 0, 0, 0.05);
    background-color: var(--white);
    margin-bottom: 16px;
  }

  .overviewCardStandardsHeader {
    font-size: calc(17px + var(--accessibility-font-size)) !important;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: -0.3px;
    color: var(--grey-80);
    margin-top: 24px;
    margin-bottom: 8px;
  }

  .overviewCardStandardCode {
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: -0.2px;
    color: var(--grey-90);
    flex-shrink: -1;
    min-width: 120px;
  }

  .overviewCardStandardCount {
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: -0.2px;
    color: var(--grey-90);
    flex-shrink: -1;
  }

  .overviewCardStandardGraphContainer {
    display: flex;
    flex: 1;
    /* width: 100%; */
    height: 14px;
    /* margin: 4px 40px; */
    margin-left: 4px;
    margin-right: 20px;
    border-radius: 4px;
    background-color: var(--grey-20);
    overflow: hidden;
  }

  .overviewCardStandardDescription {
    font-size: 12px;
    font-weight: var(--accessibility-bold-text);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.1px;
    color: var(--grey-80);
    padding-bottom: 12px !important;
  }
}

@media (max-width: 385px) {
  .developmentProgressPillOverflow {
    margin-left: 0;
    margin-right: 0;
    margin-top: 6px !important;
  }
}

@media (max-width: 620px) {
  .developmentProgressPillOverflow {
    margin-left: 0;
    margin-right: 0;
    margin-top: 6px !important;
  }
}

@media (max-width: 700px) {
  .completedStandardsContainer {
    height: unset !important;
    display: flex;
    flex-direction: column !important;
    align-items: unset !important;
  }

  .totalStandards {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.2px;
    color: var(--grey-60);
    margin-bottom: 6px;
  }

  .completedStandardsGraphContainer {
    height: 8px !important;
    flex: 1;
    align-self: unset !important;
    display: flex;
    border-radius: 2px;
    background-color: var(--grey-30);
    margin-top: 6px;
    margin-bottom: 7px;
    overflow: hidden;
  }

  .graphFilledSegment {
    height: 8px !important;
  }

  .graphEmptySegment {
    height: 8px !important;
  }

  .graphSeparator {
    height: 8px !important;
  }

  .nosCardDetails {
    font-size: calc(15px + var(--accessibility-font-size)) !important;
    font-weight: var(--accessibility-bold-text);
    font-stretch: normal;
    font-style: normal;
    line-height: var(--accessibility-line-height);
    letter-spacing: -0.3px;
    color: var(--grey-90);
    margin-top: 4px;
    margin-bottom: 12px;
  }
}
