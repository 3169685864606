.editRolesContent {
  align-items: center;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 32px;
  margin-left: auto;
  margin-right: auto;
  max-width: 406px;
  width: 100%;
  background-color: var(--white);
  min-height: calc(100% - 208px - 120px);
}

.editRolesHeader {
  font-size: calc(11px + var(--accessibility-font-size));
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--accessibility-line-height);
  letter-spacing: normal;
  color: var(--grey-70);
}

.hcpcInputContainer {
  width: 100%;
  height: 50px;
  display: flex;
  border-radius: 8px;
  border: solid 0.5px var(--grey-50);
  background-color: var(--white);
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  color: var(--black) !important;
}

.saveHCPCButton {
  /* Container */
  cursor: pointer;
  min-height: 56px !important;
  background-color: transparent;
  display: flex;
  align-items: center;
  position: absolute;
  top: -3px;
  right: 12px;
  /* Text */
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.3px;
  text-align: right;
  color: var(--cta-blue);
  margin-left: 12px;
  text-decoration: var(--accessibility-underline-links);
  text-decoration-color: currentColor;
}

.saveHCPCButton:disabled {
  opacity: 0.5;
  text-decoration: unset !important;
}

.saveHCPCButtonSpinnerContainer {
  position: absolute;
  top: -3px;
  right: 0px;
  height: 56px;
  padding-right: 0px;
  width: 58.7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}

.saveHCPCButtonSpinner {
  --color: var(--cta-blue);
  width: 24px;
  height: 24px;
}
