.infoPage p,
.infoPage li {
  font-size: 17px;
  font-weight: var(--accessibility-bold-text);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -0.3px;
  color: var(--grey-90) !important;
}

.infoPage p {
  margin-top: 17px;
  margin-bottom: 17px;
}

.infoPage ol {
  list-style: decimal !important;
  padding-inline-start: 20px !important;
}

.infoPage li {
  padding-inline-start: 8px !important;
}

.infoPage a {
  color: var(--cta-blue);
  text-decoration: underline;
}

.infoPage .infoPageTitle {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -0.4px;
  color: var(--grey-90) !important;
}
